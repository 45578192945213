@font-face {
	font-family: "sgicons";
	src: url("assets/fonts/sgicons/font/iplatform-iconfont.eot?37560840");
	src: url("assets/fonts/sgicons/font/iplatform-iconfont.eot?37560840#iefix") format("embedded-opentype"),
		url("assets/fonts/sgicons/font/iplatform-iconfont.woff?37560840") format("woff"),
		url("assets/fonts/sgicons/font/iplatform-iconfont.ttf?37560840") format("truetype"),
		url("assets/fonts/sgicons/font/iplatform-iconfont.svg?37560840#certiblokicons") format("svg");
	font-weight: normal;
	font-style: normal;
}

i {
	font-family: "sgicons";
	font-style: normal;
	font-weight: normal;
	speak: never;
	font-size: 24px;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;

	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */

	/* You can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}
